.SideBar {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  float: left;
  padding: 20px 0 40px 10px;
  background-color: #4b20ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SideBarFooter {
  display: none;
}

/* =======================HEADER======================= */
.Header {
  display: flex;
  align-items: center;
  width: 80%;
}

.Header p {
  margin-left: 10px;
  font-weight: bold;
  font-size: xx-large;
}

.Header span {
  border-bottom: 3px solid white;
}

.Header img {
  height: 75px;
}

/* ====================FOOTER=========================*/
.Footer {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Contact {
  margin: 15px 0;
}

.ContactTitle {
  font-weight: bold;
  font-size: 15px;
}

.ContactInfo {
  margin-top: 5px;
}

.ContactSocialMedia {
  margin-top: 10px;
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.ContactSocialMedia a {
  color: white;
  font-size: 25px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.ContactSocialMedia .linkedin:hover {
  color: #2877b5;
  /* animation-name: bounce;
  -moz-animation-name: bounce; */
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.ContactSocialMedia .facebook:hover {
  color: #186aa8;
  /* animation-name: bounce;
  -moz-animation-name: bounce; */
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.ContactSocialMedia .twitter:hover {
  color: #0191ff;
  /* animation-name: bounce;
  -moz-animation-name: bounce; */
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.ContactSocialMedia .github:hover {
  color: #000;
  /* animation-name: bounce;
  -moz-animation-name: bounce; */
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


@media only screen and (max-width: 1200px) {
  .SideBar {
    display: none;
  }

  .SideBarFooter {
    display: flex;
    position: static;
    float: unset;
    width: 100%;
    height: unset;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .Header {
    display: block;
    width: unset;
  }

  .Header img {
    width: unset;
  }

  .Footer {
    height: unset;

  }
}