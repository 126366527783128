.ProjectsContainer {
  margin-left: 300px;
  margin-bottom: 50px;
  width: 80%;
}

.Projects {
  display: flex;
  flex-wrap: wrap;
}

.ProjectsTitle {
  font-size: 50px;
  margin: 10px 0 50px 50px;
}

.Project {
  border: 1px solid #eaecef;
  width: 350px;
  margin: 10px;
}

.Project div {
  width: 100%;
}

.Project .ProjectHeader img {
  width: 100%;
  border: 1px solid;
  height: 223px;
}

.Project .ProjectBody {
  padding: 10px;
}

.Project .ProjectBody .ProjectName {
  height: 42px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Project .ProjectBody .ProjectName a {
  border-color: #423f3f;
  color: #423f3f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: transparent;
  border: none;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  text-align: center;
  outline: none;
  font-weight: 500;
  text-decoration: none;
  transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
}

.Project .ProjectBody .ProjectName a:hover {
  background-color: #423f3f;
  color: #fff;
}

.Project .ProjectBody .ProjectName a .ArrowRight {
  margin-left: 5px;
}

.Project .ProjectBody p {
  font-weight: bold;
}

.Project .ProjectFooter {
  border-top: 1px solid #eaecef;
  padding: 10px;
}

.Project .ProjectFooter span {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin-right: 5px;
}

@media only screen and (max-width: 1200px) {
  .ProjectsContainer {
    margin-left: 0;
    margin-bottom: 50px;
    width: 100%;
  }

  .Project {
    max-width: 90%;
    margin: 10px auto;
  }

  .Project .ProjectBody p {
    margin-right: 5px;
    word-wrap: break-word;
    width: 60%;
  }
}
