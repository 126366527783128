.AboutContainer {
  height: 100vh;
}

.AboutDetails {
  /* margin-left: 300px; */
  height: 80vh;
  display: flex;
  padding-top: 6%;
}

.AboutDetails .menu {
  position: absolute;
  top: 1%;
  right: 2%;
  height: 100px;
  font-size: 50px;
}

.AboutDetailsLeft {
  display: flex;
  align-items: center;
  position: relative;
  left: 200px;
  width: 100%;
}

.AboutDetailsImage {
  text-align: center;
}

.AboutDetailsImage img {
  width: 440px;
  height: 644px;
  box-shadow: 0 0 20px rgb(90, 89, 89);
  border-radius: 50px;
}

.small {
  display: none;
}

.HorizontalLine {
  width: 150px;
  height: 3px;
  background-color: #4b20ff;
  position: relative;
  bottom: 86px;
  right: 50px;
}

.AboutDetailsText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
}

.AboutDetailsFirstName, .AboutDetailsLastName {
  font-size: 70px;
  font-weight: bold;
}

.AboutDetailsLastName {
  color: #4b20ff;
  line-height: 0.7;
}

.AboutDetailsShortDescription {
  margin: 25px 0;
  font-weight: bold;
}

.AboutDetailsLongDescription {
  margin: 25px 0;
}

/* .AboutDetailsAction {
  display: flex;
  justify-content: space-evenly;
} */

.AboutDetailsAction button{
  cursor: pointer;
  margin: 0.25rem;
  padding: 1.0rem 2.5rem;
  font-size: 1rem;
  border-radius: 50px;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  transition: background 250ms ease-in-out, transform 250ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.AboutDetailsActionPortfolio {
  border: none;
  color: white;
  background-color: #4b20ff;
}

.AboutDetailsActionPortfolio:hover,
.AboutDetailsActionPortfolio:focus {
  background-color: #643efd;
}

.AboutDetailsActionResume {
  color: black;
  background-color: white;
  border: 2px solid black;
}

.AboutDetailsActionResume a {
  color: #000;
  text-decoration: none;
}

.AboutDetailsActionResume:hover,
.AboutDetailsActionResume:focus {
  background-color: #4b20ff;
  border: 2px solid #4b20ff;
}

.AboutDetailsActionResume:hover a,
.AboutDetailsActionResume:focus a {
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  .AboutContainer {
    height: fit-content;
  }

  .AboutDetails {
    height: unset;
    margin-bottom: 50px;
  }

  .AboutDetailsImage img {
    flex-direction: column;
    width: 168px;
    height: 168px;
    border-radius: 50%;
    box-shadow: none;
  }

  .AboutDetailsText {
    align-items: center;
    width: 90%;
  }

  .AboutDetailsTextName {
    display: flex;
    align-items: center;
  }

  .AboutDetailsFirstName, .AboutDetailsLastName {
    font-size: 50px;
  }

  .AboutDetailsFirstName {
    margin-right: 10px;
  }

  .HorizontalLine {
    display: none;
  }

  .AboutDetailsLeft {
    width: 100%;
    flex-direction: column;
    position: static;
  }

  .large {
    display: none;
  }

  .small {
    display: block;
  }
}